
import { defineComponent, ref, computed } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import AuthWhiteBlock from '@/components/pages/auth/AuthWhiteBlock.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import AuthPhoneVerification from '@/components/pages/auth/AuthPhoneVerification.vue'

export default defineComponent({
  components: {
    AuthWhiteBlock,
    TmAlert,
    AuthPhoneVerification,
    PageAuth,
  },
  setup() {
    const code = ref<string>('')
    const sendSmsError = ref(false)
    const alertText = computed(() => {
      if (sendSmsError.value) {
        return 'Please try to send a new SMS in 12 minutes.'
      }

      if (code.value.length === 4) {
        return 'Please enter a valid 4-digit code we sent to your mobile phone.'
      }

      return ''
    })

    return {
      sendSmsError,
      code,
      alertText,
    }
  },
})


import { defineComponent } from 'vue'
import CodeConfirmation from '@/components/pages/auth/CodeConfirmation.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModalCloseBtn from '@/components/shared/modal/TmModalCloseBtn.vue'

export default defineComponent({
  components: {
    TmButton,
    TmModalCloseBtn,
    CodeConfirmation,
  },
  props: {
    modelValue: {
      type: String,
    },
    title: {
      type: String,
      default: 'We sent you an SMS code to:',
    },
    hideAnswer: {
      type: Boolean,
    },
    btnText: {
      type: String,
      default: 'Log me in securely',
    },
    showModalClose: {
      type: Boolean,
    },
  },
  emits: ['sendSms', 'update:modelValue'],
})
